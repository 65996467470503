import React, { Fragment } from "react";
import {
  createRoutesFromElements,
  Route,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Home from "./routes/Home";
import Auth from "./routes/Auth";
import NotFound from "./routes/NotFound";
import ErrorBoundary from "./routes/ErrorBoundary";
import DashboardRoute from "./routes/dashboard";
import Availablepages from "./routes/Gigs/available/available";
import JobdescriptionPages from "./routes/Gigs/available/jobdescription";
import ProgressPages from "./routes/Gigs/progress/progress";
import ProgressDescriptionPages from "./routes/Gigs/progress/progressdescription";
import ReviewPages from "./routes/Gigs/review/review";
import ReviewdescriptionPages from "./routes/Gigs/review/reviewdescription";
import RevisionPages from "./routes/Gigs/revision/revision";
import Revisiondescription from "./components/Gigs/revision/revisiondescription";
import CompletedGigsPage from "./routes/Gigs/completed/completed";
import DisputedGigsPage from "./routes/Gigs/disputed/disputed";
import DisputeddescriptionPage from "./routes/Gigs/disputed/disputeddescription";
import RecordPage from "./routes/records";
import ProfilePages from "./routes/profile";
import CreateorderPage from "./routes/order/createorder";
import Services from "./routes/Services";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import FAQ from "./routes/Faq";
import ContactUs from "./routes/ContactUs";
import Blogs from "./routes/blogs";
import Singleblogs from "./routes/singleblogs";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route element={<ProtectedRoutes />}>
        <Route
          path="/dashboard"
          element={<DashboardRoute />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/available"
          element={<Availablepages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/job-description/:orderid"
          element={<JobdescriptionPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/progress"
          element={<ProgressPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/progress-description/:orderid"
          element={<ProgressDescriptionPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/review"
          element={<ReviewPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/review-description/:orderid"
          element={<ReviewdescriptionPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/revision"
          element={<RevisionPages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/revision-description/:orderid"
          element={<Revisiondescription />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/complete"
          element={<CompletedGigsPage />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/dispute"
          element={<DisputedGigsPage />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/disputed-description/:orderid"
          element={<DisputeddescriptionPage />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/history"
          element={<RecordPage />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/profile"
          element={<ProfilePages />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/post-order"
          element={<CreateorderPage />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      <Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />
      <Route
        path="/services"
        element={<Services />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/privacy-policy"
        element={<PrivacyPolicy />}
        errorElement={<ErrorBoundary />}
      />
      <Route path="/faq" element={<FAQ />} errorElement={<ErrorBoundary />} />
      <Route
        path="/blogs"
        element={<Blogs />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/single-blog/:id"
        element={<Singleblogs />}
        errorElement={<ErrorBoundary />}
      />
     
      <Route
        path="/contact-us"
        element={<ContactUs />}
        errorElement={<ErrorBoundary />}
      />
      <Route path="/auth" element={<Auth />} errorElement={<ErrorBoundary />} />
      <Route path="*" element={<NotFound />} errorElement={<ErrorBoundary />} />
    </Fragment>
  )
);
const Routes = () => {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default Routes;
