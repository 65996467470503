import React from "react";
import { Link } from "react-router-dom";
import LandingImage from "../Assets/landing.jpg";
import Tawk from "./Tawk/tawk";
const Landing = () => {
  return (
    <>
      <div className="h-full flex justify-center items-center">
        <div className="w-[90%] h-[50vh] xs:w-[80%] xs:h-[80vh] relative rounded overflow-hidden">
          <img
            src={LandingImage}
            alt=""
            className="absolute z-[1] top-0 left-0 w-full h-full"
          />
          <div className="absolute z-[2] top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-[#0009]">
            <h1 className="text-white text-2xl md:text-6xl 2xl:text-8xl text-center font-extrabold">
              ONE-STOP SOLUTION FOR EXPERT FREELANCE SERVICES
            </h1>
            <p className="text-white text-center">
              “Sit Back and Let the Best Freelancers Take Care of It for You”{" "}
            </p>
            <Link
              to="/auth?t=login"
              className="h-10 mb-4 mt-4 px-4 py-2 bg-indigo-700 text-white select-none m-auto rounded-md cursor-pointer"
            >
              get started
            </Link>
          </div>
        </div>
      </div>
      <Tawk />
    </>
  );
};

export default Landing;
