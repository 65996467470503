import React from 'react'

const About = () => {
  return (
    <div className="bg-white px-[5%]" id="about">
      <div className="py-2">
        <div className="">
          <h1 className="text-black text-4xl mb-2 text-center font-extrabold">
            About Us
          </h1>
          <p className="text-black text-center">
            Freelance Experts is a testament to our core values, deeply
            ingrained in our ethos. We don't just offer a platform for Employers
            and Freelancers to collaborate; we do it uniquely, in our own
            special way. Our goal is to become the ultimate destination for
            professionals seeking connection, collaboration, and productivity.
            We believe in fostering an environment that's both flexible and
            secure, where the best work thrives. Transparency breeds trust, and
            within our community, meritocracy reigns supreme. Geographical,
            political, and religious differences fade away as individuals unite
            to seize economic opportunities. Since 2013, we've been dedicated to
            transforming these ideals into reality. Over the years, we've built
            strong relationships with our users, adapting our platform to meet
            their evolving needs. We learn from our users, and together, we
            evolve and grow. Join us and become a valued member of our expanding
            community!
          </p>
        </div>
        <div className="my-4">
          <div className="my-2">
            <h2 className="text-black text-center font-extrabold text-2xl">
              Mission
            </h2>
            <p className="text-black text-center">
              {' '}
              To empower freelancers and businesses worldwide by providing a
              dynamic platform that fosters collaboration, creativity, and
              growth. Our mission is to connect talent with opportunity,
              enabling individuals and organizations to thrive in the
              ever-evolving freelance economy.
            </p>
          </div>
          <div className="my-2">
            <h2 className="text-black text-center font-extrabold text-2xl">
              Vision
            </h2>
            <p className="text-black text-center">
              Our vision is to be the leading global destination for freelancers
              and businesses seeking seamless, efficient, and rewarding
              collaborations. We strive to create a vibrant community where
              talent is recognized, valued, and empowered to achieve their full
              potential.
            </p>
          </div>
          <div className="my-2">
            <h2 className="text-black text-center font-extrabold text-2xl">
              Philosophy
            </h2>
            <p className="text-black text-center">
              At our core, we believe in the power of human connection and the
              limitless possibilities it brings. We are committed to fostering a
              culture of inclusivity, transparency, and mutual respect. By
              upholding these principles, we aim to build a platform where
              creativity flourishes, innovation thrives, and success knows no
              bounds.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
