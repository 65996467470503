import React, { useState, useEffect } from "react";
import "./jobdescription.css";
import Navbar from "../../Header";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../api/axios";
import StarRating from "./StarRating";
import moment from "moment";
import Loader from "../../../loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Jobdescription() {
  const { orderid } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  // console.log(orderDetails)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const GetOrder = async () => {
    try {
      setLoading(true);
      const res = await api("GET", `singleorder/${orderid}`);
      setOrderDetails(res.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/");
      } else if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetOrder();
  }, [orderid]);

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  return (
    <>
      <Navbar />
      {loading && <Loader />}
      <main className="main-dashboard">
        <div className="main-dashboard-container job-desc-container">
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "21px",
              marginTop: "20px",
            }}
          >
            Order Details
          </p>

          <div className="gig-details">
            <div className="bids">
              <div className="job-name">
                <p>{orderDetails?.title || "N/A"}</p>
              </div>

              <div className="job-description">
                <p>{orderDetails?.description || "N/A"}</p>
              </div>

              <div className="files">
                <p>Attached Files</p>
                {orderDetails?.files && orderDetails.files.length > 0 ? (
                  orderDetails.files.map((file, index) => (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.split("/").pop()}
                    </a>
                  ))
                ) : (
                  <p>No files attached</p>
                )}
              </div>

              <div className="bids-section">
                <p>Bids</p>
                {orderDetails?.bids && orderDetails.bids.length > 0 ? (
                  orderDetails.bids.map((bid, index) => (
                    <div key={index} className="bid">
                      <p>
                        <strong>Freelancer ID:</strong> {bid.freelancerid}
                      </p>
                      <p>
                        <strong>Freelancer Rating:</strong>{" "}
                        <StarRating rating={bid.freelanceraverageRating || 0} />
                      </p>
                      <p>
                        <strong>Bid Text:</strong> {bid.bidtext}
                      </p>
                      <button className="assign-button">
                        Best freelancer will be picked by admin
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No bids. Bids: 0</p>
                )}
              </div>
            </div>

            <div className="details">
              <div className="specifics">
                <p style={{ color: "white", fontWeight: "bold" }}>
                  Order Specifications
                </p>

                <div className="specifics-container">
                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Order Name</p>
                    </div>
                    <div className="gig-specs-value overflow-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {orderDetails?.title || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Deadline</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {orderDetails?.deadline
                          ? formatDeadline(orderDetails.deadline)
                          : "No Deadline"}
                      </p>
                    </div>
                  </div>

                  {/* <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Payment Date</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "green", fontWeight: "bold" }}>
                        {orderDetails?.payment_date || "N/A"}
                      </p>
                    </div>
                  </div> */}

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Category</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {orderDetails?.category || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>SubCategory</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {orderDetails?.subcategories || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Budget</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        ${orderDetails?.budget || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Status</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "green", fontWeight: "bold" }}>
                        {orderDetails?.status || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Jobdescription;
