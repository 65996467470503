import React, { useState } from 'react'
import Footer from '../components/Footer'
import HeaderLanding from '../components/HeaderLanding'

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const faqs = [
    {
      question: 'What services does Freelance Experts offer?',
      answer:
        'Freelance Experts offers a wide range of services including Education & Training, Writing and Translation, Programming & Development, Art & Design, Customer Support & Administrative, Legal, and Accounting and Finance.',
    },
    {
      question: 'How can I register on Freelance Experts?',
      answer:
        "You can register by clicking the 'Sign Up' button on our homepage and filling in your personal details.",
    },
    {
      question: 'How do I contact customer support?',
      answer:
        'You can contact our customer support team by emailing support@freelanceexperts.com or using the contact form on our website.',
    },
    {
      question: 'What payment methods do you accept?',
      answer:
        'We accept various payment methods including credit cards, PayPal, and bank transfers.',
    },
    {
      question: 'Can I request a refund?',
      answer:
        'Yes, you can request a refund within 30 days of your purchase. Please refer to our refund policy for more details.',
    },
    // Add more placeholder questions as needed
  ]

  return (
    <div className="">
      <HeaderLanding />
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-4xl font-bold text-center mb-8">
          Frequently Asked Questions
        </h1>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-4">
              <button
                className="w-full text-left text-lg font-medium text-blue-600"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
              </button>
              {openIndex === index && (
                <p className="mt-2 text-gray-700">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FAQ
