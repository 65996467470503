import React from "react";
import DisputedDescription from "../../../components/Gigs/disputed/disputeddescription";
function DisputeddescriptionPage() {
  return (
    <>
      <DisputedDescription />
    </>
  );
}

export default DisputeddescriptionPage;
