import React, { useEffect, useState } from "react";
import Navbar from "../../Header";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import api from "../../../api/axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import moment from "moment";
function Completed() {
  const [complete, setComplete] = useState([]);
  // console.log(complete)
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);

  useEffect(() => {
    const getCompleteOrder = async () => {
      try {
        setLoading(true);
        const res = await api("GET", "completedorder"); // Adjusted for correct API calling syntax
        if (Array.isArray(res.data.completedorder)) {
          setComplete(res.data.completedorder); // Correctly access the array of orders
        } else {
          setComplete([]);
        }
        setLoading(false);
      } catch (error) {
        // console.error("Failed to fetch completed orders:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCompleteOrder();
  }, []);

  // Get current orders for pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = Array.isArray(complete)
    ? complete.slice(indexOfFirstOrder, indexOfLastOrder)
    : [];

  // Change page functions
  const nextPage = () => {
    if (currentPage < Math.ceil(complete.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <main className="main-dashboard">
        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Completed Orders
            </p>
            <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>Order Name</th>
                    <th>Budget</th>
                    {/* <th>Deadline</th> */}
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <AiOutlineLoading3Quarters className="loading-icon" />
                      </td>
                    </tr>
                  ) : currentOrders.length === 0 ? (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "600" }}>No Completed Order</p>
                      </td>
                    </tr>
                  ) : (
                    currentOrders.map((order) => (
                      <tr key={order._id}>
                        <td style={{ fontWeight: "600" }}>
                          {order ? order.title : "N/A"}
                        </td>
                        <td style={{ color: "green", fontWeight: "600" }}>
                          ${order ? order.budget : "N/A"}
                        </td>

                        <td style={{ fontWeight: "600" }}>
                          {order ? order.category : "N/A"}
                        </td>
                        <td style={{ fontWeight: "600", color: "green" }}>
                          {order ? order.status : "N/A"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <MdKeyboardDoubleArrowLeft
                  className={`pagination-icon ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={prevPage}
                />
                <span className="pagination-number">{currentPage}</span>
                <MdKeyboardDoubleArrowRight
                  className={`pagination-icon ${
                    currentOrders.length < ordersPerPage ? "disabled" : ""
                  }`}
                  onClick={nextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Completed;
