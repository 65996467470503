import React from "react";
import Disputed from "../../../components/Gigs/disputed/disputed";
function DisputedGigsPage() {
  return (
    <>
      <Disputed />
    </>
  );
}

export default DisputedGigsPage;
