import React from 'react'
import HeaderLanding from '../components/HeaderLanding'
import Footer from '../components/Footer'

const Services = () => {
  return (
    <div className="">
      <HeaderLanding />
      <div className="max-w-7xl mx-auto p-6">
        <h1 className="text-4xl font-bold text-center mb-8">OUR SERVICES</h1>
        <p className="text-lg text-center mb-12">
          At Freelance Experts, we offer a comprehensive range of services to
          meet your diverse needs across various domains.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">
              Education & Training
            </h2>
            <p>
              Freelance Experts provides a wide array of educational services
              tailored to your requirements. Whether you're seeking educational
              instruction, tutoring in mathematics or science, or educational
              consulting, our platform connects you with experienced
              professionals to support your learning journey. Additionally, we
              offer specialized expertise in nursing, medical research, and
              English language instruction, among other areas, ensuring you
              receive top-notch guidance and support. From content writing to
              data management, our services encompass every aspect of education
              and training, empowering you to excel in your academic endeavors.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">
              Writing and Translation
            </h2>
            <p>
              Freelance Experts is your go-to destination for high-quality
              content creation and translation services. Our talented writers
              and translators specialize in various fields, including article
              writing, copywriting, and creative writing, delivering engaging
              and impactful content tailored to your needs. Whether you require
              proofreading, editing, or translation services, our team is
              dedicated to delivering exceptional results. With expertise in a
              multitude of languages and writing styles, we ensure that your
              message is conveyed effectively and accurately to your target
              audience.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">
              Programming & Development
            </h2>
            <p>
              Freelance Experts offers a comprehensive suite of solutions to
              bring your digital projects to life. From web development and
              software development to mobile app development and database
              management, our team of skilled developers and programmers is
              equipped to handle projects of any scale and complexity. With
              expertise in a wide range of programming languages and
              technologies, we deliver innovative and scalable solutions that
              meet your unique requirements. Whether you're building a website,
              developing a software application, or creating a custom database
              solution, Freelance Experts has you covered.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">Art & Design</h2>
            <p>
              Freelance Experts provides a platform for talented artists and
              designers to showcase their creativity and expertise. From graphic
              design and logo design to illustration and animation, our artists
              and designers specialize in a variety of visual mediums, bringing
              your ideas to life with stunning visuals and captivating designs.
              Whether you're in need of branding materials, promotional
              graphics, or multimedia content, our team of creative
              professionals is dedicated to delivering visually compelling
              solutions that make a lasting impression.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">
              Customer Support & Administrative
            </h2>
            <p>
              Freelance Experts offers a range of solutions to streamline your
              business operations and enhance customer satisfaction. From data
              entry and management to virtual assistance and customer service,
              our team of experienced professionals is here to support your
              administrative needs. Whether you require assistance with
              day-to-day tasks, project management, or customer inquiries, our
              dedicated team is committed to providing efficient and reliable
              support that allows you to focus on growing your business.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">Legal</h2>
            <p>
              Freelance Experts offers a comprehensive range of services to meet
              your legal needs. From legal advisory and contract expertise to
              paralegal support and legal document preparation, our team of
              legal experts is here to provide you with the guidance and
              assistance you need to navigate complex legal matters. Whether
              you're in need of legal research, drafting services, or litigation
              support, our team is dedicated to delivering timely and effective
              solutions that protect your interests and uphold your rights.
            </p>
          </div>

          <div className="service-card p-2 border border-slate-500">
            <h2 className="text-2xl font-semibold mb-4">
              Accounting and Finance
            </h2>
            <p>
              Freelance Experts offers a wide range of solutions to help you
              manage your finances and make informed business decisions. From
              accounting and financial analysis to bookkeeping and payroll
              processing, our team of accounting professionals is here to
              provide you with the expertise and support you need to achieve
              your financial goals. Whether you're a small business owner, an
              entrepreneur, or a corporate executive, our team is committed to
              delivering personalized solutions that meet your unique needs and
              objectives.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services
