import React from "react";
import "./revisionModal.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function RevisionModal({
  isOpen,
  onClose,
  revisioncomment,
  setrevisioncomment,
  onSubmit,
  isSubmitting,
}) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h2>Are you sure you want to Revise the Order?</h2>
        <form onSubmit={onSubmit}>
          <label>Revision Comments</label>
          <textarea
            className="input"
            value={revisioncomment}
            onChange={(e) => setrevisioncomment(e.target.value)}
            rows="8"
            cols="38"
            placeholder="Write your Revision comments here"
          ></textarea>
          <button type="submit">
            {isSubmitting ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "Submit"
            )}
          </button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
}

export default RevisionModal;
