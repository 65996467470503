import React, { useEffect, useState } from "react";
import Navbar from "../../Header";
import DisputeModal from "./disputemodal/deposit/disputeModal";
import api from "../../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { color } from "framer-motion";
import moment from "moment";
import Loader from "../../../loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RevisionDescription() {
  const { orderid } = useParams();
  const [isDisputedModalOpen, setDisputedModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const res = await api("GET", `/getmysingleorder/${orderid}`);
      setOrderDetails(res.data);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching order details:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/");
      } else if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderid]);

  if (!orderDetails)
    return (
      <>
        <Loader />
      </>
    );
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  return (
    <>
      <DisputeModal
        isOpen={isDisputedModalOpen}
        onClose={() => setDisputedModalOpen(false)}
        orderid={orderid}
      />
      <Navbar />

      <main className="main-dashboard">
        <div className="main-dashboard-container job-desc-container">
          <p className="heading">Revision Order Details</p>
          <div className="gig-details">
            <div className="bids">
              <div className="job-name">
                <p>{orderDetails.title}</p>
              </div>
              <div className="job-description">
                <p>{orderDetails.description}</p>
              </div>
              <div className="comments">
                <p>Comments on the Revision</p>
                <p className="comments-text">{orderDetails.revisioncomment}</p>
              </div>
              <div className="files">
                <p>Attached Files</p>
                {orderDetails.files &&
                  orderDetails.files.map((file, index) => (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}
                <p>Submitted Files</p>
                {orderDetails.submittedfiles &&
                  orderDetails.submittedfiles.map((file, index) => (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}
              </div>
              <div className="div-buttons dispute-order">
                <button onClick={() => setDisputedModalOpen(true)}>
                  Dispute Order
                </button>
              </div>
            </div>
            <div className="details">
              <div className="specifics">
                <p style={{ color: "white", fontWeight: "bold" }}>
                  Gig Specifications
                </p>
                <div className="specifics-container">
                  <div className="gig-specs">
                    <p>Status</p>
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      {orderDetails.status}
                    </p>
                  </div>
                  <div className="gig-specs">
                    <p>Deadline</p>
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      {new Date(orderDetails.deadline).toLocaleString()}
                    </p>
                  </div>
                  {/* <div className="gig-specs">
                    <p>Payment Date</p>
                    <p style={{ color: "green", fontWeight: "bold" }}>
                      {new Date(orderDetails.payment_date).toLocaleDateString()}
                    </p>
                  </div> */}
                  <div className="gig-specs">
                    <p>Category</p>
                    <p style={{ color: "white", fontWeight: "bold" }}>
                      {orderDetails.category}
                    </p>
                  </div>
                  <div className="gig-specs">
                    <p>SubCategory</p>
                    <p style={{ color: "white", fontWeight: "bold" }}>
                      {orderDetails.subcategories}
                    </p>
                  </div>
                  <div className="gig-specs">
                    <p>Budget</p>
                    <p style={{ color: "white", fontWeight: "bold" }}>
                      ${orderDetails.budget}
                    </p>
                  </div>
                  <div className="gig-specs">
                    <p>Paper Format</p>
                    <p style={{ color: "white", fontWeight: "bold" }}>
                      {orderDetails.paperformat}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default RevisionDescription;
