import React from "react";
import Jobdescription from "../../../components/Gigs/available/jobdescription";

function JobdescriptionPages() {
  return (
    <>
      <Jobdescription />
    </>
  );
}

export default JobdescriptionPages;
