import React, { useState, useEffect } from "react";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { cn } from "../utils/cn";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { toast } from "react-toastify";
import { useAppDispatch } from "../state/hook";
import { setIsLogged } from "../state/actions/loggedAction";
import { useSearchParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Tawk from "../components/Tawk/tawk";

export default function Auth() {
  const [searchParams] = useSearchParams();
  const [regdata, setRegData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
  });
  const [logdata, setLogData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("login");
  const query_tab = searchParams.get("t");

  useEffect(() => {
    if (query_tab) {
      setTab(query_tab);
    }
  }, [query_tab]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRegSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await api("POST", "register", {
      ...regdata,
      name: `${regdata.first_name} ${regdata.last_name}`,
      contact: regdata.phone_number,
    });

    setLoading(false);
    if (res.status >= 200 && res.status <= 299) {
      toast.success("Check Your Email To verify your email");
      setTimeout(() => {
        setTab("login");
      }, 2000);
    } else {
      toast(res.data.message, { type: "error" });
    }
  };

  const handleLogSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await api("POST", "login", logdata);
    setLoading(false);
    if (res.status >= 200 && res.status <= 299) {
      dispatch(setIsLogged({ isLogged: true, userData: res.data }));
      toast("Login Successful", { type: "success" });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } else {
      toast(res.data.message, { type: "error" });
    }
  };

  const handlePhoneChange = (value) => {
    setRegData({ ...regdata, phone_number: value });
  };

  if (tab === "login") {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="mt-4 max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
          <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
            Welcome to FreeLance Experts
          </h2>

          <form className="my-8" onSubmit={handleLogSubmit}>
            <LabelInputContainer className="mb-4">
              <Label htmlFor="email">Email Address</Label>
              <Input
                id="email"
                placeholder="projectmayhem@fc.com"
                type="email"
                value={logdata.email}
                name="email"
                onChange={(e) => {
                  setLogData({ ...logdata, [e.target.name]: e.target.value });
                }}
              />
            </LabelInputContainer>

            <LabelInputContainer className="mb-4">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                placeholder="••••••••"
                type="password"
                value={logdata.password}
                name="password"
                onChange={(e) => {
                  setLogData({ ...logdata, [e.target.name]: e.target.value });
                }}
              />
            </LabelInputContainer>

            <button
              className="mb-4 bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
              type="submit"
            >
              {loading ? (
                <div className="dot-flashing"></div>
              ) : (
                <span>Sign In &rarr;</span>
              )}
              <BottomGradient />
            </button>

            <span className="select-none">
              Don't have an account{" "}
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => {
                  setTab("register");
                }}
              >
                register here
              </span>
            </span>
          </form>
          <a
            href="https://thelegalfiledrafts.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            <p>Click Here if You are a FreeLancer</p>
          </a>
        </div>
      </div>
    );
  } else if (tab === "register") {
    return (
      <>
        <div className="h-screen flex items-center justify-center">
          <div className="mt-4 max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
            <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
              Welcome to FreeLance Experts
            </h2>

            <form className="my-8" onSubmit={handleRegSubmit}>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
                <LabelInputContainer>
                  <Label htmlFor="firstname">First name</Label>
                  <Input
                    id="firstname"
                    placeholder="Tyler"
                    type="text"
                    value={regdata.first_name}
                    name="first_name"
                    onChange={(e) => {
                      setRegData({
                        ...regdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <Label htmlFor="lastname">Last name</Label>
                  <Input
                    id="lastname"
                    placeholder="Durden"
                    type="text"
                    value={regdata.last_name}
                    name="last_name"
                    onChange={(e) => {
                      setRegData({
                        ...regdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </LabelInputContainer>
              </div>
              <LabelInputContainer className="mb-4">
                <Label htmlFor="email">Email Address</Label>
                <Input
                  id="email"
                  placeholder="projectmayhem@fc.com"
                  type="email"
                  value={regdata.email}
                  name="email"
                  onChange={(e) => {
                    setRegData({ ...regdata, [e.target.name]: e.target.value });
                  }}
                />
              </LabelInputContainer>

              <LabelInputContainer className="mb-8">
                <Label htmlFor="phonenumber">Phone Number</Label>
                <div className="wrap-input-9 w-full">
                  <PhoneInput
                    defaultCountry="ke"
                    className="input w-full"
                    placeholder="Enter phone number"
                    value={regdata.phone_number}
                    onChange={handlePhoneChange}
                    required
                  />
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
              </LabelInputContainer>

              <LabelInputContainer className="mb-4">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  placeholder="••••••••"
                  type="password"
                  value={regdata.password}
                  name="password"
                  onChange={(e) => {
                    setRegData({ ...regdata, [e.target.name]: e.target.value });
                  }}
                />
              </LabelInputContainer>

              <button
                className="mb-4 bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                type="submit"
              >
                {loading ? (
                  <div className="dot-flashing"></div>
                ) : (
                  <span>Sign Up &rarr;</span>
                )}
                <BottomGradient />
              </button>

              <span className="select-none">
                Already have an account{" "}
                <span
                  className="text-blue-600 cursor-pointer"
                  onClick={() => {
                    setTab("login");
                  }}
                >
                  login here
                </span>
              </span>
            </form>
            <a
              href="https://thelegalfiledrafts.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600"
            >
              <p>Click Here if You are a FreeLancer</p>
            </a>
          </div>
        </div>
        <Tawk />
      </>
    );
  }
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
