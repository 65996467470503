import React, { useState, useEffect } from "react";
import "./profile.css";
import Navbar from "../Header";
import ProfilePic from "../../Assets/personIcon.png";
import api from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Profile() {
  const [bio, setBio] = useState("");
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(ProfilePic);
  const [imageurl, setImageUrl] = useState();
  // console.log(imageurl);
  const handleImageUploadClick = () => {
    document.getElementById("image-upload-input").click();
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "upload");

      try {
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/drh36px3j/upload",
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await res.json();
        // console.log(data);
        setProfilePic(data.secure_url);
        // setLoading(false);
        toast.success("Image uploaded successfully!");
      } catch (error) {
        // setLoading(false);
        toast.error("Error uploading image");
        console.error("Error uploading image", error);
      }
    }
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = { bio, profilePic };

      const res = await api("PUT", "updatedetails", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(res);

      if (res.status === 200) {
        setLoading(false);

        toast.success("Profile updated successfully");
      }

      if (res.data.profilePic) {
        setImageUrl(res.data.profilePic);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error updating profile");
      // console.error("Error updating profile", error);
    } finally {
      setLoading(false);
    }
  };

  const [userProfile, setUserProfile] = useState();
  // console.log(userProfile)

  useEffect(() => {
    const GetProfile = async () => {
      try {
        const res = await api("GET", "getclientdetails");
        // console.log(res)
        setUserProfile(res.data.profilePic);
      } catch (err) {
        // console.log(err)
      }
    };

    GetProfile();
  }, []);

  return (
    <>
      <Navbar />
      <main className="main-dashboard">
        <div className="dashboard-render profile-dashboard-render">
          <div className="main-dashboard-container job-desc-container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Profile
            </p>

            <div className="profile-main">
              <div className="profile-container">
                <div className="profile-pic">
                  <img
                    src={userProfile ? userProfile : profilePic}
                    alt="profile"
                    className="profile-pic-image"
                  />
                  <p className="choose-image" onClick={handleImageUploadClick}>
                    Choose Image
                  </p>
                  <input
                    id="image-upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </div>

                <form className="bio" onSubmit={handleSubmit}>
                  <div className="actual-bio">
                    <p
                      style={{
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Tell us more about yourself
                    </p>
                    <textarea
                      rows="10"
                      cols="50"
                      className="bio-textarea"
                      placeholder="Write your bio here..."
                      value={bio}
                      onChange={handleBioChange}
                    ></textarea>
                  </div>

                  <div className="subject-specific">
                    <div className="save-profile-button">
                      <button className="btn-52" disabled={loading}>
                        {loading ? (
                          <AiOutlineLoading3Quarters className="loading-icon" />
                        ) : (
                          <div className="original">Save Profile</div>
                        )}
                        {!loading && (
                          <div className="letters">
                            <span>S</span>
                            <span>A</span>
                            <span>V</span>
                            <span>E</span>
                            <span>-</span>
                            <span>P</span>
                            <span>R</span>
                            <span>O</span>
                            <span>F</span>
                            <span>I</span>
                            <span>L</span>
                            <span>E</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Profile;
