import React, { useState } from "react";
import "./ratingModal.css";
import { useNavigate } from "react-router-dom";
import api from "../../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function RatingModal({ isOpen, onClose, assignedto }) {
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.warn("Please select a rating before submitting");
      return;
    }
    try {
      await api("POST", "rating", {
        assignedto,
        rating,
      });

      toast.success("Rating submitted successfully");
      onClose();
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error) {
      // console.error("Failed to submit rating:", error);
    }
  };

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal-content">
        <h2>Rate the Freelancer</h2>
        <form onSubmit={handleSubmit}>
          <label>Rating</label>
          <div className="stars">
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`star ${index < rating ? "selected" : ""}`}
                onClick={() => handleStarClick(index)}
              >
                &#9733;
              </span>
            ))}
          </div>
          <div className="buttons">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RatingModal;
