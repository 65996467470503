import React from "react";
import Records from "../components/history/records";

function RecordPage() {
  return (
    <>
      <Records />
    </>
  );
}

export default RecordPage;
