import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../Header";
import api from "../../../api/axios";
import moment from "moment";
import Loader from "../../../loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProgressDescription() {
  const { orderid } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const res = await api("GET", `/singleorder/${orderid}`);
      setOrderDetails(res.data);
      setLoading(false);
    } catch (error) {
      // console.log(error.message);
      if (error.response && error.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/");
      } else if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderid]);

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  return (
    <>
      <Navbar />
      {loading && <Loader />}

      <main className="main-dashboard">
        <div className="main-dashboard-container job-desc-container">
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "21px",
              marginTop: "20px",
            }}
          >
            Orders In Progress Details
          </p>

          {orderDetails ? (
            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>{orderDetails.title}</p>
                </div>

                <div className="job-description">
                  <p>{orderDetails.description}</p>
                </div>

                <div className="files">
                  <p>Attached Files</p>
                  <ul>
                    {orderDetails.files.map((file, index) => (
                      <li key={index}>
                        <a
                          href={file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          File {index + 1}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Order Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Order Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.title || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Status</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {orderDetails.status || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Deadline</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {orderDetails.deadline
                            ? formatDeadline(orderDetails.deadline)
                            : "No Deadline"}
                        </p>
                      </div>
                    </div>

                    {/* <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Payment Date</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {orderDetails.payment_date || "N/A"}
                        </p>
                      </div>
                    </div> */}

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.category || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>SubCategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.subcategories || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          ${orderDetails.budget || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Assigned </p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.assignedto || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Loading order details...</p>
          )}
        </div>
      </main>
    </>
  );
}

export default ProgressDescription;
