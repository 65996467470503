import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from '../state/hook'

const ProtectedRoutes = () => {
  const { isLogged } = useAppSelector((state) => state.logged)
  let location = useLocation()
  return isLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/auth?t=login" state={{ from: location }} replace />
  )
}

export default ProtectedRoutes
