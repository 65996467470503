import React from "react";
import ProgressDescription from "../../../components/Gigs/progress/progressdescription";

function ProgressDescriptionPages() {
  return (
    <>
      <ProgressDescription />
    </>
  );
}

export default ProgressDescriptionPages;
