import React from "react";
import Createorder from "../../components/order/createorder";

function CreateorderPage() {
  return (
    <>
      <Createorder />
    </>
  );
}

export default CreateorderPage;
