import React from "react";
import Revision from "../../../components/Gigs/revision/revision";

function RevisionPages() {
  return (
    <>
      <Revision />
    </>
  );
}

export default RevisionPages;
