// src/components/Navbar/useDropdown.js
import { useState, useEffect, useRef } from "react";

export default function useDropdown(initialState = false) {
  const [dropdownVisible, setDropdownVisible] = useState(initialState);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return {
    dropdownVisible,
    setDropdownVisible,
    dropdownRef,
    toggleDropdown,
  };
}
