import React from "react";
import Reviewdescription from "../../../components/Gigs/review/reviewdescription";

function ReviewdescriptionPages() {
  return (
    <>
      <Reviewdescription />
    </>
  );
}

export default ReviewdescriptionPages;
