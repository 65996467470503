import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Header";
import RevisionModal from "./revisionmodal/deposit/revisionModal";
import DisputeModal from "../revision/disputemodal/deposit/disputeModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../api/axios";
import RatingModal from "./ratingmodal/RatingModal";
import Loader from "../../../loader/loader";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function ReviewDescription() {
  const { orderid } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [isRevisionModalOpen, setRevisionModalOpen] = useState(false);
  const [isDisputeModalOpen, setDisputeModalOpen] = useState(false);
  const [isRatingModalOpen, setRatingModalOpen] = useState(false); // State for RatingModal
  const [revisioncomment, setrevisioncomment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const res = await api("GET", `singleorder/${orderid}`);
      setOrderDetails(res.data);
      setLoading(false);
      // console.log(res.data);
    } catch (error) {
      // console.log(error.message);
      if (error.response && error.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/");
      } else if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlerevisioncommentChange = (e) => {
    setrevisioncomment(e.target.value);
  };

  const navigate = useNavigate();

  const handlePostRevision = async (e) => {
    e.preventDefault();
    if (!revisioncomment.trim()) {
      toast.warn("Please fill in the revision comments");
      return;
    }
    try {
      setIsSubmitting(true);
      const res = await api("POST", "revision", {
        orderid,
        revisioncomment,
      });
      // console.log(res.data);
      setIsSubmitting(false);

      setRevisionModalOpen(false);
      toast.success("Revision comment posted successfully");

      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error) {
      // console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [completeLoad, setCompleteLoad] = useState(false);

  const handleCompleteOrder = async () => {
    try {
      setCompleteLoad(true);
      const res = await api("POST", "completeorder", { orderid });
      // console.log("completed", res.data);
      setCompleteLoad(false);
      if (res.status === 200) {
        toast.success("Order marked as complete");
        setRatingModalOpen(true); // Open rating modal
      }
    } catch (error) {
      // console.log(error.message);
    } finally {
      setCompleteLoad(false);
    }
  };

  const handleDisputeOrder = () => {
    setDisputeModalOpen(true);
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderid]);

  if (!orderDetails) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <div>Error: {error}</div>;
  if (!orderDetails) return <div>No order details available.</div>;

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  return (
    <>
      <RevisionModal
        isOpen={isRevisionModalOpen}
        onClose={() => setRevisionModalOpen(false)}
        revisioncomment={revisioncomment}
        setrevisioncomment={setrevisioncomment}
        onSubmit={handlePostRevision}
      >
        isSubmitting={isSubmitting}
        <textarea
          value={revisioncomment}
          onChange={handlerevisioncommentChange}
          placeholder="Enter your revision comment"
        />
        <button>Post Revision</button>
      </RevisionModal>

      <DisputeModal
        isOpen={isDisputeModalOpen}
        onClose={() => setDisputeModalOpen(false)}
        orderid={orderid}
      />

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={() => setRatingModalOpen(false)}
        assignedto={orderDetails.assignedto}
      />

      <Navbar />

      <main className="main-dashboard">
        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              In Review Order Details
            </p>

            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>{orderDetails.title}</p>
                </div>

                <div className="job-description">
                  <p>{orderDetails.description}</p>
                </div>

                <div className="files">
                  <p>Attached Files</p>
                  {orderDetails.files.map((file, index) => (
                    <a
                      href={file}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}
                  <p>Submitted Files</p>
                  {orderDetails.submittedfiles.map((file, index) => (
                    <a
                      href={file}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}

                  {orderDetails.revisionfiles &&
                    orderDetails.revisionfiles.length > 0 && (
                      <>
                        <p>Revised Files</p>
                        {orderDetails.revisionfiles.map((file, index) => (
                          <a
                            href={file}
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File {index + 1}
                          </a>
                        ))}
                      </>
                    )}
                </div>

                <div className="div-buttons">
                  <div className="revise-order">
                    <button onClick={() => setRevisionModalOpen(true)}>
                      Revise Order
                    </button>
                  </div>
                  <div className="complete-order">
                    <button onClick={handleCompleteOrder}>
                      {completeLoad ? (
                        <AiOutlineLoading3Quarters className="loading-icon" />
                      ) : (
                        "Complete Order"
                      )}
                    </button>
                  </div>
                  <div className="dispute-order">
                    <button onClick={handleDisputeOrder}>Dispute Order</button>
                  </div>
                </div>
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Gig Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Gig Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.title || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Status</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {orderDetails.status || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Deadline</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {orderDetails?.deadline
                            ? formatDeadline(orderDetails.deadline)
                            : "No Deadline"}
                        </p>
                      </div>
                    </div>

                    {/* <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Payment Date</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {new Date(
                            orderDetails.payment_date
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div> */}

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.category || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>SubCategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {orderDetails.subcategories || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          ${orderDetails.budget || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReviewDescription;
