import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#242424] px-[5%]">
      <div className="max-w-screen-2xl mx-auto mb-12">
        <div className="mb-8 pt-8">
          <h3 className="text-white text-xl mb-4">Company</h3>
          <div className="">
            <ul className="flex items-center justify-between flex-wrap">
              <li>
                <Link
                  to="/#about"
                  className="text-white font-light capitalize underline"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/#testimonials"
                  className="text-white font-light capitalize underline"
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-white font-light capitalize underline"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className="text-white font-light capitalize underline"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  How it Works
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="text-white font-light capitalize underline"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  Fair Use Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  Payment Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white font-light capitalize underline"
                >
                  Don't buy accounts
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <h3 className="text-white text-xl mb-4">Our Services</h3>
          <div className="flex justify-between flex-col md:flex-row">
            <div className="">
              <ul>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Education & Training
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Writing and Translation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Programming & Development
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <ul>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Art & Design
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Customer Support & Administrative
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Legal
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <ul>
                <li>
                  <Link
                    to="/services"
                    className="text-white font-light capitalize underline"
                  >
                    Accounting and Finance
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-4">
        <p className="text-center text-white">
          Freelance Tutors . All Rights Reserved
        </p>
        <p className="text-center text-white">
          Developed By{" "}
          <a
            href="https://github.com/Astro-Devs-Tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            Astro Devs Ltd
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
