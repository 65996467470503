import { combineReducers } from 'redux'
import loggedReducer from './loggedReducer'
import pageReducer from './pageReducer'

const allReducers = combineReducers({
  logged: loggedReducer,
  page: pageReducer,
})

export default allReducers
