import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'
import BackgroundBeams from '../components/ui/background-beams'
import HeaderLanding from '../components/HeaderLanding'
import Landing from '../components/Landing'
import Footer from '../components/Footer'
import About from '../components/About'
import Testimonials from '../components/Testimonials'
import Meta from '../components/Meta'

const Home = () => {
  const location = useLocation()

  const data = {
    title: 'Homepage',
    subject: 'Homepage',
    keywords:
      'blogs, freelancing, writing, legal drafts, AI Training, Promotions',
    summary:
      'Freelance Experts offers remote employment opportunities. Clients post jobs, freelancers bid, work, and receive payments. Efficient, global freelance marketplace.',
    description:
      'Freelance Experts connects global clients with skilled freelancers. Clients post jobs, freelancers bid, complete tasks, and get paid. Empower your projects with remote talent',
    og_image: window.location.href + '/logo.png',
  }

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substring(1), {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    }
  }, [location])
  return (
    <div className="">
      <Meta data={data} />
      <div className="h-[65vh] xs:h-screen bg-white flex flex-col">
        <BackgroundBeams />
        <div className="absolute top-0 left-0 z-10 h-full w-full flex flex-col">
          <HeaderLanding />
          <div className="pt-8 xs:pt-0 xs:flex-1">
            <Landing />
          </div>
        </div>
      </div>
      <About />
      <Testimonials />
      <Footer />
    </div>
  )
}

export default Home
