import React from "react";
import CompletedGigs from "../../../components/Gigs/completed/completed";
function CompletedGigsPage() {
  return (
    <>
      <CompletedGigs />
    </>
  );
}

export default CompletedGigsPage;
