import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const HeaderLanding = () => {
  const [mobiNav, setMobiNav] = useState(false);
  return (
    <div className="bg-[#242424] h-14">
      <div className="max-w-screen-2xl h-full w-full flex items-center justify-between mx-auto px-[5%]">
        <div className="flex-1">
          <Link to="/" className="hidden xs:block text-white text-2xl">
            FreeLance Experts
          </Link>
          <div className="block xs:hidden">
            <MenuIcon
              className="text-white text-4xl cursor-pointer"
              onClick={() => {
                setMobiNav(true);
              }}
            />
          </div>
        </div>
        <div className="mx-2">
          <div className="hidden xs:block">
            <div className="flex items-center justify-end gap-4 ">
              <Link to="/blogs" className="text-white">
                Blogs
              </Link>
              <Link to="/contact-us" className="text-white">
                Contact Us
              </Link>
              <Link to="/services" className="text-white">
                Services
              </Link>
              <Link to="/#testimonials" className="text-white">
                Testimonials
              </Link>
              <Link to="/#about" className="text-white">
                About Us
              </Link>
              <Link
                to="/auth?t=login"
                className="h-10 mb-4 mt-4 px-4 py-2 bg-indigo-700 text-white select-none m-auto rounded-md cursor-pointer"
              >
                Sign In
              </Link>
              <Link
                to="/auth?t=register"
                className="h-10 mb-4 mt-4 px-3 py-[7px] border-[1px] border-indigo-700  text-white select-none m-auto rounded-md cursor-pointer"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div className="block xs:hidden">
            <div className="flex items-center justify-end gap-4 ">
              <Link
                to="/auth?t=login"
                className="h-10 mb-4 mt-4 px-4 py-2 bg-indigo-700 text-white select-none m-auto rounded-md cursor-pointer"
              >
                Sign In
              </Link>
              <Link
                to="/auth?t=register"
                className="h-10 mb-4 mt-4 px-3 py-[7px] border-[1px] border-indigo-700  text-white select-none m-auto rounded-md cursor-pointer"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute top-0 ${
          mobiNav ? "left-0" : "-left-[100%]"
        } h-screen w-full z-20 bg-white transition-all block xs:hidden`}
      >
        <div className="h-full w-full relative">
          <CloseIcon
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => {
              setMobiNav(false);
            }}
          />
          <div className="pt-14 flex flex-col">
            <Link
              to="/contact-us"
              className="p-2 text-black border-b border-slate-600"
            >
              Contact Us
            </Link>
            <Link
              to="/services"
              className="p-2 text-black border-b border-slate-600"
            >
              Services
            </Link>
            <Link
              to="/#testimonials"
              className="p-2 text-black border-b border-slate-600"
            >
              Testimonials
            </Link>
            <Link
              to="/#about"
              className="p-2 text-black border-b border-slate-600"
            >
              About Us
            </Link>
            <Link
              to="/blogs"
              className="p-2 text-black border-b border-slate-600"
            >
              Blogs
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLanding;
