import React from "react";
import Profile from "../components/profile/profile";
function ProfilePages() {
  return (
    <>
      <Profile />
    </>
  );
}

export default ProfilePages;
