import React, { useEffect, useState } from "react";
import "./records.css";
import Navbar from "../Header";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaPrint } from "react-icons/fa6";
import api from "../../api/axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";

function Available() {
  const [transactions, setTransactions] = useState([]);
  // console.log(transactions)
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);

  const getTransactions = async () => {
    try {
      const res = await api("GET", "totalpaid");
      setTransactions(res.data.order);
      setTotalAmount(res.data.totalAmount);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const printPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Date and Time", "Transaction Reference","Currency", "Amount", "Status"]],
      body: transactions.map((transaction) => [
        new Date(transaction.createdAt).toLocaleString(),
        transaction.reference,
        transaction.currency,
        transaction.amount,
        transaction.status,
      ]),
    });
    doc.save("transactions.pdf");
  };

  const printCSV = () => {
    const csv = Papa.unparse({
      fields: ["Date and Time", "Transaction Reference","Currency", "Amount", "Status"],
      data: transactions.map((transaction) => [
        new Date(transaction.createdAt).toLocaleString(),
        transaction.reference,
        transaction.currency,
        transaction.amount,
        transaction.status,
      ]),
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = Array.isArray(transactions)
    ? transactions.slice(indexOfFirstOrder, indexOfLastOrder)
    : [];

  // Change page functions
  const nextPage = () => {
    if (currentPage < Math.ceil(transactions.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <main className="main-dashboard">
        <div className="dashboard-render">
          <div className="dashboard-render-container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Transaction History
            </p>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              Total Amount:{" "}
              <span style={{ fontWeight: "600", color: "green" }}>
                {totalAmount}
              </span>
            </p>
            <div className="table-container">
              {loading ? (
                <div className="loading-container">
                  <AiOutlineLoading3Quarters className="loading-icon" />
                  <span>Loading...</span>
                </div>
              ) : currentOrders.length === 0 ? (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No transaction history at the moment.
                </p>
              ) : (
                <>
                  <div className="print-buttons">
                    <FaPrint
                      className="print-icon"
                      title="Print transaction history as PDF"
                      onClick={printPDF}
                    />
                    <FaPrint
                      className="print-icon"
                      title="Print transaction history as CSV"
                      onClick={printCSV}
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>Date and Time</th>
                        <th>Transaction Reference</th>
                        <th>Currency</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOrders.map((transaction) => (
                        <tr key={transaction._id}>
                          <td style={{ fontWeight: "600" }}>
                            {new Date(transaction.createdAt).toLocaleString()}
                          </td>
                          <td style={{ fontWeight: "700" }}>
                            {transaction.reference || 'N/A'}
                          </td>
                          <td style={{ fontWeight: "600" }}>
                            {transaction.currency || 'N/A'}
                          </td>
                          <td style={{ fontWeight: "600", color: "orange" }}>
                            {transaction.amount || 'N/A'}
                          </td>
                          <td style={{ fontWeight: "600", color: "green" }}>
                            {transaction.status || 'N/A'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              <div className="pagination">
                <MdKeyboardDoubleArrowLeft
                  className={`pagination-icon ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={prevPage}
                />
                <span className="pagination-number">{currentPage}</span>
                <MdKeyboardDoubleArrowRight
                  className={`pagination-icon ${
                    currentOrders.length < ordersPerPage ? "disabled" : ""
                  }`}
                  onClick={nextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Available;
