import React from "react";
import Progress from "../../../components/Gigs/progress/progress";

function ProgressPages() {
  return (
    <>
      <Progress />
    </>
  );
}

export default ProgressPages;
