import React, { useState } from "react";
import "./disputeModal.css";
import api from "../../../../../api/axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function DisputeModal({ isOpen, onClose, orderid }) {
  const [disputeComment, setDisputeComment] = useState("");
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!disputeComment) {
      toast.warn("Enter the reason for dispute");
      return;
    }

    try {
      setLoading(true);
      const res = await api("POST", "initiatedispute", {
        orderid,
        reasondispute: disputeComment,
      });
      setLoading(false);
      toast.success("Dispute initiated successfully:");
      onClose();
      setTimeout(() => {
        Navigate("/dashboard");
      }, 1000);
    } catch (error) {
      // console.error("Failed to initiate dispute:", error);
      if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal-content">
        <h2>Dispute the Order</h2>
        <form onSubmit={handleSubmit}>
          <label>Dispute Comments</label>
          <textarea
            className="input"
            value={disputeComment}
            onChange={(e) => setDisputeComment(e.target.value)}
            placeholder="Write your Dispute comments here"
            rows="8"
            cols="38"
          />
          <div className="buttons">
            <button type="submit">
              {loading ? (
                <AiOutlineLoading3Quarters className="loading-icon" />
              ) : (
                "Submit"
              )}
            </button>
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DisputeModal;
