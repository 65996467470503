import React from 'react'
import HeaderLanding from '../components/HeaderLanding'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
  return (
    <div className="">
      <HeaderLanding />
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-4xl font-bold text-center mb-8">PRIVACY POLICY</h1>
        <p className="text-lg mb-6">
          At Freelance Experts, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines how we collect, use, and safeguard your data when you
          visit our website or use our services.
        </p>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            1. Information We Collect:
          </h2>
          <ul className="list-disc list-inside">
            <li>
              <span className="font-semibold">Personal Information:</span> When
              you register on our platform or engage with our services, we may
              collect personal information such as your name, email address,
              contact details, and payment information.
            </li>
            <li>
              <span className="font-semibold">Cookies:</span> We use cookies and
              similar tracking technologies to enhance your browsing experience,
              analyze usage patterns, and personalize content and
              advertisements.
            </li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            2. How We Use Your Information:
          </h2>
          <ul className="list-disc list-inside">
            <li>
              To provide and improve our services, personalize your experience,
              and communicate with you about your account and transactions.
            </li>
            <li>
              To analyze usage trends, monitor the effectiveness of our
              marketing efforts, and optimize our website and services.
            </li>
            <li>
              To comply with legal obligations, enforce our terms of service,
              and protect the rights, property, and safety of our users and
              Freelance Experts.
            </li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            3. Data Sharing and Disclosure
          </h2>
          <ul className="list-disc list-inside">
            <li>
              We don’t share your personal information with any third-party
              service providers or freelancers providing services to you.
            </li>
            <li>
              We may disclose your information in response to legal requests,
              court orders, or legal proceedings, or to enforce our policies,
              protect our rights, or address security or technical issues.
            </li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
          <ul className="list-disc list-inside">
            <li>
              We implement industry-standard security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction.
            </li>
            <li>We cannot guarantee absolute security for your data.</li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            5. Your Rights and Choices
          </h2>
          <ul className="list-disc list-inside">
            <li>
              You have the right to access, update, or delete your personal
              information, and to opt-out of receiving marketing communications
              from us.
            </li>
            <li>
              You can manage your cookie preferences and settings through your
              browser or device settings.
            </li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">6. Children's Privacy</h2>
          <p>
            Our website and services are not intended for children under the age
            of 13, and we do not knowingly collect personal information from
            children.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            7. Changes to this Privacy Policy
          </h2>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any changes by posting the updated
            policy on our website.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
