import React, { useEffect, useState } from "react";
import Navbar from "../../Header";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import api from "../../../api/axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import moment from "moment";
function Disputed() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);

  useEffect(() => {
    const getDisputed = async () => {
      try {
        setLoading(false);
        const res = await api("GET", "/disputedorders");
        if (Array.isArray(res.data)) {
          setOrders(res.data);
        } else {
          setOrders([]);
        }

        setLoading(false);
      } catch (error) {
        // console.error(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getDisputed();
  }, []);

  // Get current orders for pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = Array.isArray(orders)
    ? orders.slice(indexOfFirstOrder, indexOfLastOrder)
    : [];

  // Change page functions
  const nextPage = () => {
    if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <main className="main-dashboard">
        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Disputed Orders
            </p>
            <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>Order Name</th>
                    <th>Budget</th>
                    {/* <th>Deadline</th> */}
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <AiOutlineLoading3Quarters className="loading-icon" />
                      </td>
                    </tr>
                  ) : currentOrders.length === 0 ? (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "600" }}>No Disputed Orders</p>
                      </td>
                    </tr>
                  ) : (
                    currentOrders.map((order) => (
                      <Link
                        to={`/disputed-description/${order._id}`}
                        className="table-link"
                        key={order._id}
                      >
                        <tr>
                          <td style={{ fontWeight: "600" }}>
                            {order ? order.title : "N/A"}
                          </td>
                          <td style={{ color: "green", fontWeight: "600" }}>
                            ${order ? order.budget : "N/A"}
                          </td>

                          <td style={{ fontWeight: "600" }}>
                            {order ? order.category : "N/A"}
                          </td>
                          <td style={{ fontWeight: "bold", color: "red" }}>
                            {order ? order.status : "N/A"}
                          </td>
                        </tr>
                      </Link>
                    ))
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <MdKeyboardDoubleArrowLeft
                  className={`pagination-icon ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={prevPage}
                />
                <span className="pagination-number">{currentPage}</span>
                <MdKeyboardDoubleArrowRight
                  className={`pagination-icon ${
                    currentOrders.length < ordersPerPage ? "disabled" : ""
                  }`}
                  onClick={nextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Disputed;
