import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const Meta = ({ data }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{`${data.title}`} | Freelance Experts</title>
        <link
          rel="canonical"
          href={
            window.location.href +
            window.location.pathname +
            window.location.search
          }
        />

        {/** Add your Measurement ID from Google analytics here */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WLQ2XXCQSK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-WLQ2XXCQSK');
          `}
        </script>

        <meta name="keywords" content={data.keywords} />
        <meta name="description" content={data.description} />
        <meta name="subject" content={data.subject} />
        <meta name="copyright" content="Freelance Experts" />
        <meta name="language" content="EN" />
        <meta name="robots" content="index, follow" />
        <meta name="revised" content="Sunday, July 18th, 2010, 5:15 pm" />
        <meta name="abstract" content="" />
        <meta name="topic" content={data.title + "| Freelance Experts"} />
        <meta name="summary" content={data.summary + "| Freelance Experts"} />
        <meta name="Classification" content="IT, technology, Business" />
        <meta name="author" content="Stephen Nzioka, stipnzioka@gmail.com" />
        <meta name="designer" content="Stephen Nzioka" />
        <meta name="reply-to" content="stipnzioka@gmail.com" />
        <meta name="owner" content="" />
        <meta name="url" content={window.location.href} />
        <meta name="identifier-URL" content={window.location.href} />
        <meta name="directory" content="submission" />
        <meta
          name="category"
          content="IT, Technology, Business, Writing, Legal"
        />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta http-equiv="Expires" content="0" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Cache-Control" content="no-cache" />

        <meta name="robots" content="index" />
        <link rel="author" href="humans.txt" />

        <meta
          property="og:url"
          content={
            window.location.href +
            window.location.pathname +
            window.location.search
          }
        />
        <meta property="og:type" content="blog" />
        <meta
          property="og:title"
          content={data.title + "| Freelance Experts"}
        />
        <meta
          property="og:description"
          content={data.summary + "| Freelance Experts"}
        />
        <meta property="og:image" content={data.og_image} />

        <meta
          name="twitter:card"
          content={data.summary + "| Freelance Experts"}
        />
        <meta
          name="twitter:url"
          content={
            window.location.href +
            window.location.pathname +
            window.location.search
          }
        />
        <meta
          name="twitter:title"
          content={data.title + "| Freelance Experts"}
        />
        <meta
          name="twitter:description"
          content={data.summary + "| Freelance Experts"}
        />
      </Helmet>
    </Fragment>
  );
};

export default Meta;
