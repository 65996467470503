const pageReducer = (state = 'dashboard', action) => {
  switch (action.type) {
    case 'SETPAGE':
      return action.payload
    default:
      return state
  }
}

export default pageReducer
