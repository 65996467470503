import React, { useEffect, useState } from "react";
import "../styles/css/blogs.css";
import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import api from "../api/axios.js";
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Import the loading icon
import { Link } from "react-router-dom";
import BlogImage from "../Assets/support.jpg";
import Tawk from "../components/Tawk/tawk.jsx";

function Blogs() {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const GetBlogdata = async () => {
      try {
        setLoading(true);
        const res = await api("GET", "getblogs");
        // console.log(res);
        setBlogData(res.data.blogs);
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    };

    GetBlogdata();
  }, []);

  return (
    <>
      <HeaderLanding />

      <section className="blogs-section">
        <div className="blog-section-inner">
          <div className="blog-image-display">
            <img src={BlogImage} alt="blogImage" className="blogImage" />

            <div className="blog-title-div">
              <p className="blog-title-div-p">FreeLance Experts Blogs</p>
            </div>
          </div>

          {loading ? (
            <div className="loading">
              <AiOutlineLoading3Quarters className="loading-icon" />
            </div>
          ) : blogData.length === 0 ? (
            <p className="no-blogs">No blogs at the moment</p>
          ) : (
            <div className="blogs-container">
              {blogData.map((blog) => (
                <div className="blog-section-main" key={blog._id}>
                  <div className="blog-image">
                    <Link to={`/single-blog/${blog._id}`}>
                    
                      <img
                        src={blog.image}
                        alt="blog-images"
                        className="blog-image-class"
                      />
                    </Link>
                  </div>

                  <div className="blog-content">
                  
                    <p className="blog-title">{blog.title}</p>
                    <p className="blog-description">{blog.description}</p>

                    <Link to="/auth?t=login">
                      <button className="create-blog-button">Order Now</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      <Tawk />

      <Footer />
    </>
  );
}

export default Blogs;
