import React from "react";
import Available from "../../../components/Gigs/available/available";
function Availablepages() {
  return (
    <>
      <Available />
    </>
  );
}

export default Availablepages;
