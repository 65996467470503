import React, { useEffect, useState } from "react";
import Header from "./Header.jsx";
import "../styles/css/dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { GrInProgress } from "react-icons/gr";
import { AiFillFlag } from "react-icons/ai";
import { BiRevision } from "react-icons/bi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FcCancel } from "react-icons/fc";
import { FaSackDollar } from "react-icons/fa6";
import api from "../api/axios.js";
import Loader from "../loader/loader.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tawk from "./Tawk/tawk";
function Dashboard() {
  const [counts, setCounts] = useState({
    availableOrders: 0,
    completedOrders: 0,
    ordersInDispute: 0,
    ordersInProgress: 0,
    ordersInRevision: 0,
    ordersinreview: 0,
    totalAmountPaid: 0,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getOrdercounts = async () => {
    try {
      setLoading(true);
      const [orderCountsRes, totalPaidRes] = await Promise.all([
        api("GET", "getordercounts"),
        api("GET", "totalpaid"),
      ]);
      setCounts({
        ...orderCountsRes.data,
        totalAmountPaid: totalPaidRes.data.totalAmount,
      });
      setLoading(false);
      // console.log(orderCountsRes.data, totalPaidRes.data);
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/");
      } else if (error.response && error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdercounts();
  }, []);

  return (
    <>
      <section>
        <Header />
        {loading && <Loader />}
        <main className="main-dashboard">
          <div className="main-dashboard-container">
            <div className="flex-container">
              <Link to="/available" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <TiTick className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          All Orders
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.availableOrders : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/progress" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <GrInProgress className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Orders In Progress
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.ordersInProgress : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/review" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <AiFillFlag className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Orders In Review
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.ordersinreview : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/revision" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <BiRevision className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Orders In Revision
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.ordersInRevision : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/complete" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <IoCheckmarkDoneSharp className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Completed Orders
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.completedOrders : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/dispute" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <FcCancel className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Disputed Orders
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {counts ? counts.ordersInDispute : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/history" style={{ textDecoration: "none" }}>
                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <FaSackDollar className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Total paid
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        ${counts ? counts.totalAmountPaid : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </main>
      </section>
      <Tawk />
    </>
  );
}

export default Dashboard;
