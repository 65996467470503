import React, { useState } from "react";
import "./createorder.css";
import Header from "../Header";
import axios from "axios";
import api from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const subcategoryOptions = {
  "Education and Training": [
    "Educational Instruction",
    "FreeLance Writing",
    "Training",
    "Tutoring",
    "Mathematics Expertise",
    "Nursing Expertise",
    "Medical Research Expertise",
    "Data Entry",
    "English Language Expertise",
    "Data Management",
    "Mathemtics Tutoring",
    "Content Writing",
    "English Teaching",
    "Educational Consulting",
    "Typing",
    "Algebra Tutoring",
    "Science Consulting",
    "Science Teaching",
    "Design",
    "Research",
    "Calculus Tutoring",
    "Microsoft Word Expertise",
  ],
  "Writing and Translation": [
    "FreeLance Writing",
    "Data Entry",
    "English Language Expertise",
    "Typing",
    "Article Writing",
    "Translation",
    "Content Writing",
    "Blog Writing",
    "Data Management",
    "Editing",
    "Proofreading",
    "Microsoft Word Expertise",
    "Research",
    "Copy Writing",
    "Microsoft Development",
    "Creative Writing",
    "Marketing",
    "News Writing",
    "Journalism",
    "News Letter Creation",
  ],
  "Programming and Development": [
    "Web Development",
    "Software Development",
    "PHP Development",
    "WordPress Expertise",
    "HTML Development",
    "Java Development",
    "JQuery Development",
    "SQL Development",
    "HTML 5 Development",
    "Computer Programming",
    "Web Application Development",
    "C# Programming",
    "Management Consulting",
    ".Net Developement",
    "C Programming",
    "Data Annotation",
    "AI Training",
  ],
  "Art and Design": [
    "Design Services",
    "Graphic Design",
    "Logo Design",
    "Adobe Photoshop Design",
    "Editing",
    "Illustration",
    "Business Card Design",
    "Poster Design",
    "Adobe Illustrator Expertise",
    "Brochure Design",
    "Photo Editing",
    "Art Serrvices",
    "Banner Ad Design",
    "Flyer Design",
    "Video Editing",
    "Web Design",
    "Photography",
    "3D Design",
    "Animation",
    "Drawing Art",
    "Photoshop",
  ],
  "Customer Support and Administrative": [
    "Data Entry",
    "Data Management",
    "Microsoft Developement",
    "Typing",
    "Microsoft Word Expertise",
    "Research",
    "Microsoft Excel Expertise",
    "Management Consulting",
    "Virtual Assistance",
    "Customer Service",
    "Administrative Assistance",
    "FreeLance Writing",
    "Copy and Paste Tasks",
    "office Assistance",
    "Microsoft Office Expertise",
    "Transcription",
    "Marketing",
    "English Language Expertise",
    "Accounting",
    "Editing",
  ],
  Legal: [
    "Legal Advisory",
    "Drafting",
    "Contract Expertise",
    "FreeLance Writing",
    "Research",
    "Legal Research",
    "Data Entry",
    "Legal Assistance",
    "ParaLegal Support",
    "Legal Document Preparation",
    "Litigation Services",
    "Typing",
    "Consulting",
    "Legal Writing",
    "Management Consulting",
    "English Language Expertise",
    "Employment Contracting",
    "Data Management",
    "Patent Attorney Services",
    "Real Estate Consulting",
  ],
  "Accounting and Finance": [
    "Accounting",
    "Data Entry",
    "Management Consulting",
    "Financial Analysis",
    "Data Management",
    "Bookkeeping",
    "Analytics Consulting",
    "Microsoft Excel Expertise",
    "Quickbooks Consulting",
    "Payroll Processing",
    "Microsoft Development",
    "Research",
    "Marketing",
    "Consulting",
    "FreeLance Writing",
    "Financial Consulting",
    "Data Analysis",
    "Project Management",
    "Data Analysis",
    "Project Management",
    "Sales Consulting",
    "Business Planning Consulting",
    "Tax Filing",
    "Auditing",
  ],
};

const Createorder = () => {
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    category: "",
    subcategories: "",
    title: "",
    description: "",
    files: [],
    deadline: "",
    budget: "",
    paperformat: "",
  });

  // console.log(formData)

  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [payLoad, setpayLoad] = useState(false);
  const [paypalLoad, setpayPalLoad] = useState(false);
  const [paystackload, setpaystackload] = useState(false);
  const Navigate = useNavigate();

  const handleNext = () => {
    if (step === 1) {
      if (
        formData.category &&
        formData.subcategories &&
        formData.title &&
        formData.description
      ) {
        setStep(step + 1);
      } else {
        toast.warn("Please fill all the fields in Order Details.");
      }
    } else if (step === 2) {
      if (formData.deadline && formData.budget && isSubmitted) {
        setStep(step + 1);
      } else if (!isSubmitted) {
        toast.warn("Please submit the form before proceeding to Payment.");
      } else {
        toast.warn("Please fill all the fields in Advanced Details.");
      }
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "category") {
      setFormData({ ...formData, subcategories: "", [name]: value });
    }
  };

  const handleFiles = (newFiles) => {
    setFormData({ ...formData, files: [...formData.files, ...newFiles] });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    handleFiles(selectedFiles);
  };

  const removeFile = (indexToRemove) => {
    setFormData({
      ...formData,
      files: formData.files.filter((_, index) => index !== indexToRemove),
    });
  };

  const uploadFilesToCloudinary = async (files) => {
    const cloudinaryUrl = "https://api.cloudinary.com/v1_1/drh36px3j/upload";
    const uploadPreset = "upload";

    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", uploadPreset);

        const res = await axios.post(cloudinaryUrl, formData);
        return res.data.url;
      })
    );

    return uploadedFiles;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.budget ||
      !formData.subcategories ||
      !formData.deadline ||
      !formData.category ||
      !formData.description ||
      !formData.title
    ) {
      toast.warn("Please Fill in all fields");
      return;
    }

    try {
      setSubmitLoading(true);
      const uploadedFiles = await uploadFilesToCloudinary(formData.files);

      const response = await api("POST", "postorders", {
        ...formData,
        files: uploadedFiles,
      });
      // console.log(response);

      setOrderId(response.data._id);
      setSubmitLoading(false);
      toast.success("Order saved successfully, Proceed to payment");
      setIsSubmitted(true);
      setStep(3);
    } catch (error) {
      // console.log(error)
      if (error.response.status === 401) {
        toast.error("Not Authorized");
        Navigate("/");
      } else if (error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      // console.log(error)
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handlesubmitPayment = async (e) => {
    e.preventDefault();
    try {
      setpaystackload(true);
      const amount = formData.budget;
      const response = await api("POST", "initialize", {
        amount,
        orderid: orderId,
      });
      setpaystackload(false);
      window.open(response.data.authorization_url, "_blank");
    } catch (error) {
      // console.log(error)
      if (error.response.status === 401) {
        toast.error("Not Authorized");
        Navigate("/");
      } else if (error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setpaystackload(false);
    } finally {
      setpaystackload(false);
    }
  };

  const handlesubmitPaymentPaypall = async (e) => {
    e.preventDefault();
    try {
      setpayPalLoad(true);
      const amount = formData.budget;
      const response = await api("POST", "paypall", {
        amount,
        orderid: orderId,
      });
      setpayPalLoad(false);
      window.open(response.data.approvalUrl, "_blank");
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Not Authorized");
        Navigate("/");
      } else if (error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setpayPalLoad(false);
    } finally {
      setpayPalLoad(false);
    }
  };

  const handlecancelPayment = async (e) => {
    e.preventDefault();
    try {
      setpayLoad(true);
      const response = await api("post", "cancelpayment", {
        orderid: orderId,
      });
      setpayLoad(false);
      window.location.reload();
      Navigate("/https://freelancer-client.pages.dev/dashboard");
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Not Authorized");
        Navigate("/");
      } else if (error.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
      setpayLoad(false);
    } finally {
      setpayLoad(false);
    }
  };

  return (
    <>
      <Header />
      <section className="create-order-section">
        <p
          style={{
            marginTop: "20px",
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Create An Order
        </p>
        <div className="progress-steps">
          <div className={step === 1 ? "step active" : "step"}>
            Order Details
          </div>
          <div className={step === 2 ? "step active" : "step"}>
            Advanced Details
          </div>
          <div className={step === 3 ? "step active" : "step"}>Payment</div>
        </div>
        <form className="order-form" onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="step-content">
              <div className="form-group">
                <label>Category</label>
                <select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">--select--</option>
                  {Object.keys(subcategoryOptions).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Sub-Category</label>
                <select
                  name="subcategories"
                  value={formData.subcategories}
                  onChange={handleChange}
                  required
                >
                  <option value="">--select--</option>
                  {formData.category &&
                    subcategoryOptions[formData.category].map((subcategory) => (
                      <option key={subcategory} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Enter title"
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter order description"
                  required
                ></textarea>
              </div>
              <div className="file-upload-container">
                <div
                  className="file-upload-area"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <p>
                    Drag and drop files here or{" "}
                    <span
                      className="file-upload-choose"
                      onClick={() =>
                        document.querySelector(".file-input").click()
                      }
                    >
                      choose
                    </span>
                  </p>
                  <input
                    type="file"
                    multiple
                    className="file-input"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <div className="file-list">
                  {formData.files.map((file, index) => (
                    <div key={index} className="file-item">
                      <p>{file.name}</p>
                      <button
                        className="remove-button"
                        onClick={() => removeFile(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-navigation">
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="step-content">
              <div className="form-group">
                <label>Deadline</label>
                <input
                  type="datetime-local"
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleChange}
                  min={new Date().toISOString().slice(0, 16)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Budget</label>
                <input
                  type="number"
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                  placeholder="Enter Budget in USD"
                  min="0"
                  required
                />
              </div>
              <div className="form-group">
                <label>Paper Format</label>
                <select
                  name="paperformat"
                  value={formData.paperformat}
                  onChange={handleChange}
                  required
                >
                  <option value="">--select--</option>
                  <option value="APA">APA</option>
                  <option value="chicago">Chicago</option>
                  <option value="MLA">MLA</option>
                  <option value="harvard">Harvard</option>
                  <option value="ASA">ASA</option>
                  <option value="IEEE">IEEE</option>
                </select>
              </div>
              <div className="form-navigation">
                <button type="button" onClick={handleBack}>
                  Back
                </button>
                <button type="button" onClick={handleSubmit}>
                  {submitLoading ? (
                    <AiOutlineLoading3Quarters className="loading-icon" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="step-content">
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "19px",
                }}
              >
                Payment
              </p>
              <div className="confirm-payment">
                <div className="confirm-payment-container">
                  <p style={{ marginTop: "10px", fontWeight: "600" }}>
                    Are you sure you want to pay{" "}
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      ${formData.budget}
                    </span>{" "}
                    for this order?
                  </p>
                  <div className="payment-options">
                    
                    <div className="payment-option">
                      <p className="paypal">Pay Via Paystack</p>
                      <div className="confirmation-buttons">
                        <button
                          className="confirm-yes"
                          onClick={handlesubmitPayment}
                        >
                          <span role="img" aria-label="yes">
                            ✅
                          </span>{" "}
                          {paystackload ? (
                            <AiOutlineLoading3Quarters className="loading-icon" />
                          ) : (
                            "Yes"
                          )}
                        </button>
                        <button
                          className="confirm-no"
                          onClick={handlecancelPayment}
                        >
                          <span role="img" aria-label="no">
                            ❌
                          </span>{" "}
                          {payLoad ? (
                            <AiOutlineLoading3Quarters className="loading-icon" />
                          ) : (
                            "No"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "19px",
                }}
              >
                OR
              </p>

              <div className="pay-later">
                <button>Pay Later</button>
              </div>


            </div>
            
          )}
        </form>
      </section>
    </>
  );
};

export default Createorder;
