import React, { useState, useEffect } from "react";
import "../styles/css/singleblogs.css";
import { Link, useParams } from "react-router-dom";
import api from "../api/axios.js";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import BlogImage from "../Assets/support.jpg";
import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import Tawk from "../components/Tawk/tawk.jsx";

function Singleblogs() {
  const [singleBlog, setSingleBlog] = useState(null);
  //   console.log(singleBlog)
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const getSingleBlog = async () => {
      try {
        setLoading(true);
        const res = await api("GET", `getblogs/${id}`);
        setSingleBlog(res.data.blogs);
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getSingleBlog();
  }, [id]);

  return (
    <>
      <HeaderLanding />
      <section className="single-blog-section">
        <div className="single-blog-section-inner">
          <div className="blog-image-display">
            <img src={BlogImage} alt="blogImage" className="blogImage" />
            <div className="blog-title-div">
              <p className="blog-title-div-p">FreeLance Experts Blogs</p>
            </div>
          </div>
          <div className="single-blog-part">
            {loading ? (
              <div className="loading-container">
                <AiOutlineLoading3Quarters className="loading-icon" />
              </div>
            ) : (
              <>
                {singleBlog ? (
                  <>
                    <div className="single-blog-part-image">
                      <img
                        src={singleBlog.image || BlogImage}
                        alt="singleimage"
                        className="sing-image"
                      />
                    </div>
                    <div className="single-blog-p-tags">
                      <p className="single-blog-title">{singleBlog.title}</p>
                      <p className="single-blog-description">
                        {singleBlog.description}
                      </p>

                      <Link to="/auth?t=login">
                        <button className="create-blog-button single-blog-btn">
                          Order Now
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <p>Blog not found</p>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <Tawk />
      <Footer />
    </>
  );
}

export default Singleblogs;
