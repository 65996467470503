import React from "react";
import Reviews from "../../../components/Gigs/review/review";

function ReviewPages() {
  return (
    <>
      <Reviews />
    </>
  );
}

export default ReviewPages;
