import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Ryan Burrows",
    text: "Freelance Experts has revolutionized the way I access freelance services. The platform is user-friendly and the freelancers I've hired through it have consistently delivered high-quality work on time. It's a game-changer for any business looking to outsource tasks efficiently.",
    image:
      "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Jane Smith",
    text: "As a freelancer, Freelance Experts has been a fantastic platform that has allowed me to earn a living remotely. The system is efficient, secure, and connects me with clients who value my skills. It's a win-win for both freelancers and clients!",
    image:
      "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Samuel Green",
    text: "Using Freelance Experts has been a seamless experience. I was able to find a skilled freelancer within minutes and the work was delivered ahead of schedule. The platform's support team is also incredibly helpful. Highly recommended!",
    image:
      "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Laura Davis",
    text: "Freelance Experts has been instrumental in my freelance career. The platform is easy to navigate, and the payment system is transparent and timely. It has allowed me to connect with clients from all over the world and deliver quality work without any hassle.",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Michael Thompson",
    text: "The Freelance Experts platform has exceeded my expectations. The quality of freelancers available is top-notch, and the work is always completed to a high standard. It's a reliable service that I will continue to use for my business needs.",
    image:
      "https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} text-black flex items-center justify-center`}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderRadius: "50%",
        paddingTop: "1px",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} text-black flex items-center justify-center`}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderRadius: "50%",
        paddingTop: "1px",
      }}
      onClick={onClick}
    />
  );
};

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      className="bg-white px-[5%] mx-auto py-12 sm:px-6 lg:px-8"
      id="testimonials"
    >
      <h2 className="text-3xl font-extrabold text-center mb-8">Testimonials</h2>
      <Slider {...settings} className="w-[95%] xs:max-w-[50%] mx-auto">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="bg-[#242424] p-6 rounded-lg shadow-lg w-full"
          >
            <div className="flex items-center">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-20 h-19 rounded-full mr-4"
              />
              <div>
                <p className="text-lg font-semibold text-white">
                  {testimonial.name}
                </p>
                <p className="text-white mt-2 text-ellipsis line-clamp-4">
                  {testimonial.text}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
