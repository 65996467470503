import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../styles/css/header.css'
import Icon from '../Assets/personIcon.png'
import Logo from '../Assets/logo.png'
import { CgProfile } from 'react-icons/cg'
import { RiLogoutCircleRFill } from 'react-icons/ri'
import useDropdown from '../utils/navbarutils'
import { useAppSelector, useAppDispatch } from '../state/hook'
import { setIsLogged } from '../state/actions/loggedAction'
import api from '../api/axios'
import { toast } from 'react-toastify'

const Header = () => {
  const dispatch = useAppDispatch()
  const {
    dropdownVisible: profileDropdownVisible,
    setDropdownVisible: setProfileDropdownVisible,
    dropdownRef: profileDropdownRef,
  } = useDropdown()

  const handleProfileClick = () => {
    setProfileDropdownVisible(!profileDropdownVisible)
  }

  const { isLogged, userData } = useAppSelector((state) => state.logged)
  const Navigate = useNavigate()
  const handlelogout = async (e) => {
    e.preventDefault()
    dispatch(setIsLogged({ isLogged: false, userData: null }))
    try {
      const res = await api('POST', 'logout')
      // console.log(res)
      if (res.status >= 200 && res.status <= 299) {
        dispatch(setIsLogged({ isLogged: false, userData: null }))
        Navigate('/')
      }
    } catch (error) {
      // console.log(error);
      toast(error?.message, { type: 'error' })
      dispatch(setIsLogged({ isLogged: false, userData: null }))
    }
  }

  const [userProfile, setUserProfile] = useState()
  // console.log(userProfile)

  useEffect(() => {
    const GetProfile = async () => {
      try {
        const res = await api('GET', 'getclientdetails')
        // console.log(res)
        setUserProfile(res.data.profilePic)
      } catch (err) {
        // console.log(err)
      }
    }

    GetProfile()
  }, [])

  return (
    <nav className="nav-main">
      <div className="nav-content">
        <div className="left-nav">
          <div className="left-nav-image">
            <Link to="/dashboard">
              <img src={Logo} alt="icon" className="left-nav-image-Logo" />
            </Link>
          </div>
          <div className="left-nav-title">
            <Link to="/dashboard">
              <p className="p-title">FreeLance Experts</p>
            </Link>
          </div>
        </div>

        <div className="middle-nav">
          <Link to="/post-order">
            <button class="btn-31">
              <span class="text-container">
                <span class="text">Create Orders</span>
              </span>
            </button>
          </Link>
        </div>

        <div
          className="right-nav"
          onClick={handleProfileClick}
          ref={profileDropdownRef}
        >
          <div className="right-nav-main">
            <div className="right-nav-image">
              <img
                src={userProfile ? userProfile : Icon}
                alt="icon"
                className="left-nav-image-Logo"
              />
            </div>
            <div className="right-nav-title">
              <p className="profile-tag">{userData.name}</p>
            </div>
          </div>

          {profileDropdownVisible && (
            <div className="right-nav-dropdown">
              <div className="profile">
                <div>
                  <Link
                    to="/profile"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <CgProfile className="profile-dropdown-icon" />
                  </Link>
                </div>

                <div>
                  <Link
                    to="/profile"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    {' '}
                    <p>Profile</p>
                  </Link>
                </div>
              </div>

              <div className="profile">
                <div>
                  <RiLogoutCircleRFill className="profile-dropdown-icon" />
                </div>

                <div>
                  <button onClick={handlelogout}>Logout</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Header
