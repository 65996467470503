import React, { useEffect, useState } from "react";
import Navbar from "../../Header";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../api/axios";
import Loader from "../../../loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function DisputedDescription() {
  const { orderid } = useParams(); // Correctly destructure the orderid
  const [disputed, setDisputed] = useState(null); // Use null for initial state to handle loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getDisputed = async () => {
      try {
        setLoading(true);
        const res = await api("GET", `/getmysingleorder/${orderid}`); // Corrected API call
        setDisputed(res.data);
        // console.log(res.data);
        setLoading(false);
      } catch (error) {
        // console.error("Failed to fetch disputed order details:", error);
        setError("Failed to load the data");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getDisputed();
  }, [orderid]);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>{error}</p>;
  if (!disputed) return <p>No data found for this order.</p>;

  return (
    <>
      <Navbar />

      <main className="main-dashboard">
        <div className="main-dashboard-container job-desc-container">
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "21px",
              marginTop: "20px",
            }}
          >
            Disputed Order Details
          </p>

          <div className="gig-details">
            <div className="bids">
              <div className="job-name">
                <p>{disputed.title}</p>
              </div>

              <div className="job-description">
                <p>{disputed.description}</p>
              </div>

              <div className="files">
                <p>Original Attached Files</p>
                {disputed.files &&
                  disputed.files.map((file, index) => (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}
                <p>Submitted Files</p>
                {disputed.submittedfiles &&
                  disputed.submittedfiles.map((file, index) => (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File {index + 1}
                    </a>
                  ))}
                <p>Dispute Comments</p>
                <p style={{ color: "red" }}>
                  {disputed.reasondispute || "No comments available"}
                </p>
              </div>
            </div>

            <div className="details">
              <div className="specifics">
                <p style={{ color: "white", fontWeight: "bold" }}>
                  Order Specifications
                </p>

                <div className="specifics-container">
                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Order Name</p>
                    </div>
                    <div className="gig-specs-value overflow-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {disputed.title}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Status</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {disputed.status}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Category</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {disputed.category}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>SubCategory</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        {disputed.subcategories}
                      </p>
                    </div>
                  </div>

                  <div className="gig-specs">
                    <div className="gig-specs-name">
                      <p>Budget</p>
                    </div>
                    <div className="gig-specs-value">
                      <p style={{ color: "white", fontWeight: "bold" }}>
                        ${disputed.budget}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default DisputedDescription;
